<template>
    <a-drawer
        v-model:visible="visible"
        title="项目综合工时详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions :column="2">
            <a-descriptions-item label="项目编码">
                {{ detailRow?.projectCode }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ detailRow?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="生产总工时">
                {{ detailRow?.workReportTotalHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="生产标准总工时">
                {{ detailRow?.workReportStandardTotalHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="质量工作总工时">
                {{ detailRow?.qcTotalHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="质量工作标准总工时">
                {{ detailRow?.standardQcTotalHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="生产异常工时">
                {{ detailRow?.workReportExceptionTotalHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="生产额外工时">
                {{ detailRow?.otherWorkReportTotalHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="生产总时长">
                {{ detailRow?.totalHours }} h
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";

export default defineComponent({
    props: ["detailRow"],
    emits: ["update:detailRow"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailRow", null);
        };

        watch(
            () => props.detailRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.members-box {
    display: flex;
    flex-wrap: wrap;
}
</style>
